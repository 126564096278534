import { Routes, Route } from "react-router-dom";
import { AppWrapper } from "ui/layout";
import { HomePage } from "pages/home";
import "./App.css";

function App() {
  return (
    <AppWrapper>
      <Routes>
        <Route path="/" element={<HomePage />} exact={true} />
        <Route path="/usluge" element={<HomePage />} exact={true} />
        <Route path="/prednosti" element={<HomePage />} exact={true} />
        <Route path="/kontakt" element={<HomePage />} exact={true} />
      </Routes>
    </AppWrapper>
  );
}

export default App;
