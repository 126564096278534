import React from 'react';

import { Header, Footer } from 'ui/components';

export function AppWrapper({ children }) {
  return (
    <React.Fragment>
      <Header />
      <div className="page-wrapper">
        {children}
      </div>
      <Footer />
    </React.Fragment>
  );
}
