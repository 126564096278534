import React, { useState, useEffect } from "react";
import { Link, useLocation  } from "react-router-dom";

export function Header() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const openMenu = () => setMenuOpen(!isMenuOpen);

  const { pathname } = useLocation();

  useEffect(() => {
    if(pathname === '/')
    {
      handleScrollTo("section-hero")
    }
    if(pathname === '/usluge')
    {
      handleScrollTo("section-service")
    }
    if(pathname === '/prednosti')
    {
      handleScrollTo("section-advantages")
    }
    if(pathname === '/kontakt')
    {
      handleScrollTo("section-contact")
    }

  }, [pathname]); // do this on route change

  useEffect(() => {
    document.body.classList.toggle("nav-active", isMenuOpen);
  }, [isMenuOpen]);

  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      if (currentPosition > scrollTop) {
        // downscroll code
        setScrolling(false);
      } else {
        // upscroll code
        setScrolling(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleScrollTo = (scrollTo) => {
  
    var element = document.getElementById(scrollTo);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <React.Fragment>
      <header>
        <nav className={"navbar fixed-top" + (scrollTop > 0 ? " bg-dark" : "")}>
          <div className="container">
            <Link
              className="navbar-brand"
              to="/"
              aria-label="Vodopad"
            
            >
              <img src="/images/logo.svg" alt="vodopad" />
            </Link>
            <a className="nav-link tel" href="tel:+385 98 959 0130">
              <span>+385 98 959 0130</span>
            </a>
            <button
              className={"navbar-toggler " + (isMenuOpen ? "active" : "")}
              type="button"
              onClick={openMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={
                "collapse navbar-collapse " + (isMenuOpen ? "show" : "")
              }
            >
              <ul className="navbar-nav me-auto">
                <li className="nav-item ">
                  <Link
                    className="nav-link "
                    to="/usluge"
                    title="usluge"
                  >
                    <span>Usluge</span>
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    className="nav-link "
                    to="/prednosti"
                    title="prednosti"
                  >
                    <span>Prednosti</span>
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    className="nav-link "
                    to="/kontakt"
                    title="kontakt"
                  >
                    <span>Kontakt</span>
                  </Link>
                </li>
                <li className="nav-item tel">
                  <a className="nav-link " href="tel:+385 98 959 0130">
                    <img src="/icons/phone-call.svg" alt="nazovi vodopad" />{" "}
                    <span>+385 98 959 0130</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}
