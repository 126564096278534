import React from "react";

import { Advantage, Contact, Hero, Service } from "pages/home/components";

export const HomePage = () => {
  return (
    <React.Fragment>
      <div id="section-hero" className="home-page">
        <Hero />
        <Service />
        <Advantage />
        <Contact />
      </div>
    </React.Fragment>
  );
};
