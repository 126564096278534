import React from "react";

export function Service() {
  return (
    <React.Fragment>
      <section id="section-service" className="section service">
        <div className="container">
          <span className="section-title">Usluge koje nudimo</span>
          <div className="service-container">
            <div className="service-item">
              <img
                src="/icons/odstopavanje-sudopera.svg"
                alt="Odštopavanje sudopera"
                title="Odštopavanje sudopera"
              />
              <p className="service-title">Odštopavanje sudopera</p>
              <p className="service-price">50€ - 100€</p>
            </div>
            <div className="service-item">
              <img
                src="/icons/odstopavanje-umivaonika.svg"
                alt="Odštopavanje umivaonika"
                title="Odštopavanje umivaonika"
              />
              <p className="service-title">Odštopavanje umivaonika</p>
              <p className="service-price">50€ - 100€</p>
            </div>
            <div className="service-item">
              <img
                src="/icons/odstopavanje-wc-skoljke.svg"
                alt="Odštopavanje WC školjke"
                title="Odštopavanje WC školjke"
              />
              <p className="service-title">Odštopavanje WC školjke</p>
              <p className="service-price">50€ - 100€</p>
            </div>
            <div className="service-item">
              <img
                src="/icons/montaza-tus-kabine-staklena-stijena.svg"
                alt="Montaža tuš kabine/staklena stijena"
                title="Montaža tuš kabine/staklena stijena"
              />
              <p className="service-title">
                Montaža tuš kabine/staklena stijena
              </p>
              <p className="service-price">150€ - 400€</p>
            </div>
            <div className="service-item">
              <img
                src="/icons/servis-ugradbenih-vodokotlica.svg"
                alt="Servis ugradbenih vodokotlića"
                title="Servis ugradbenih vodokotlića"
              />
              <p className="service-title">Servis ugradbenih vodokotlića</p>
              <p className="service-price">50€ - 100€</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
