import React from "react";

export function Advantage() {
  return (
    <React.Fragment>
     <section id="section-advantages" className="section advantages">
          <div className="container">
            <span className="section-title">
              Zašto smo najbolji u svom poslu
            </span>
            <div className="advantage-container">
              <div className="advantage-item">
                <img
                  src="/icons/prednost-1.svg"
                  alt="Vrsimo STROJNO odstopavanje sudopera, umivaonika i WC skoljki"
                  title="Vrsimo STROJNO odstopavanje sudopera, umivaonika i WC skoljki"
                />
                <p className="advantage-description">
                  Vrsimo STROJNO odstopavanje sudopera, umivaonika i WC skoljki.
                </p>
              </div>
              <div className="advantage-item">
                <img
                  src="/icons/prednost-2.svg"
                  alt="Servisiramo i ugradbene vodokotlice (Geberit, Viega, Liv, TeCe, Grohe)."
                  title="Servisiramo i ugradbene vodokotlice (Geberit, Viega, Liv, TeCe, Grohe)."
                />
                <p className="advantage-description">
                  Servisiramo i ugradbene vodokotlice (Geberit, Viega, Liv,
                  TeCe, Grohe).
                </p>
              </div>
              <div className="advantage-item">
                <img
                  src="/icons/prednost-3.svg"
                  alt="Montiramo tus kabine i staklene stijene te sve vrste armatura (pipa)."
                  title="Montiramo tus kabine i staklene stijene te sve vrste armatura (pipa)."
                />
                <p className="advantage-description">
                  Montiramo tus kabine i staklene stijene te sve vrste armatura
                  (pipa).
                </p>
              </div>
              <div className="advantage-item">
                <img
                  src="/icons/prednost-4.svg"
                  alt="Za sve nase usluge dolazimo isti dan osim tus kabini i staklenih stijena."
                  title="Za sve nase usluge dolazimo isti dan osim tus kabini i staklenih stijena."
                />
                <p className="advantage-description">
                  Za sve nase usluge dolazimo isti dan osim tus kabini i
                  staklenih stijena.
                </p>
              </div>
            </div>
          </div>
        </section>
        
    </React.Fragment>
  );
}
