import React from "react";
import { Link } from "react-router-dom";

export function Footer() {
  const handleScrollTo = (event, scrollTo) => {
    event.preventDefault();
    var element = document.getElementById(scrollTo);
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <React.Fragment>
      <footer className="footer">
        <nav className="navbar">
          <div className="container">
            <Link className="navbar-brand" to="/" aria-label="Vodopad"  onClick={(e) => handleScrollTo(e, "section-hero")}>
              <img src="/images/logo.svg" alt="vodopad" />
            </Link>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav me-auto">
              <li className="nav-item ">
                  <Link
                    className="nav-link "
                    to="/usluge"
                    title="usluge"
                    onClick={(e) => handleScrollTo(e, "section-service")}
                  >
                    <span>Usluge</span>
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    className="nav-link "
                    to="/prednosti"
                    title="prednosti"
                    onClick={(e) => handleScrollTo(e, "section-advantages")}
                  >
                    <span>Prednosti</span>
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    className="nav-link "
                    to="/kontakt"
                    title="kontakt"
                    onClick={(e) => handleScrollTo(e, "section-contact")}
                  >
                    <span>Kontakt</span>
                  </Link>
                </li>
                <li className="nav-item tel">
                  <a className="nav-link " href="tel:+385 98 959 0130">
                    <img src="/icons/phone-call.svg"  alt="nazovi vodopad" />{" "}
                    <span>+385\98 959 0130</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </footer>
    </React.Fragment>
  );
}
