import React from "react";

export function Hero() {
  return (
    <React.Fragment>
       <section className="section hero">
          <div className="container">
            <div className="content">
              <h1>Usluge vodoinstalatera - Dolazimo u najkraćem roku</h1>
              <a className="btn-contact" href="tel:+385 98 959 0130">
                Nazovite nas
              </a>
            </div>
          </div>
        </section>
        
    </React.Fragment>
  );
}
