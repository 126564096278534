import React from "react";

export function Contact() {
  return (
    <React.Fragment>
      <section id="section-contact" className="section contact">
        <div className="container">
          <span className="section-info">
            Nazovite nas s povjerenjem na broj
            <a className="tel" href="tel:+385 98 959 0130">
              +385 98 959 0130
            </a>
            &nbsp;ili na mail: &nbsp;
            <a className="email" href="mailto:vodopad.info@gmail.com">
              vodopad.info@gmail.com
            </a>
          </span>
          <p>
            Obrt za vodoinstalacije Vodopad izdaje racune za sve obavljene
            radove, 0-24 h.
          </p>
        </div>
      </section>
    </React.Fragment>
  );
}
